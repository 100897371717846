<template>
	<el-row :gutter="40" class="mb-10">
		<el-col :span="8">
			<el-card shadow="hover">
				<template #header>
					<div class="card-header">
						<h4>{{ $t('schoolLogo') }}</h4>
					</div>
				</template>
				<div class="text-center">
					<el-upload
						class="logo-uploader"
						accept="image/jpeg,image/jpg,image/gif,image/png"
						:data="logoData"
						:action="STORAGE_API_BASE_URL"
						:drag="true"
						:multiple="false"
						:show-file-list="false"
						:on-error="handleLogoError"
						:on-success="handleLogoSuccess"
						:before-upload="beforeLogoUpload">
						<el-avatar :size="175" :src="logo" v-if="logo"></el-avatar>
						<el-avatar :size="175" v-else></el-avatar>
					</el-upload>

					<template v-if="logo">
						<el-divider></el-divider>
						<el-button @click="clearLogo()">{{ $t('clear') }}</el-button>
					</template>
				</div>
			</el-card>
			<el-card shadow="hover" class="mt-10" v-if="false">
				<template #header>
					<div class="card-header">
						<h4>{{ $t('additionnalInfo') }}</h4>
					</div>
				</template>
				<div class="text-center">
					<el-form ref="schoolMoreDataForm" :model="schoolMoreData"> </el-form>
				</div>
				<div class="card-footer">
					<div class="text-right">
						<el-button
							class="button"
							size="small"
							type="primary"
							@click="updateMoreSchoolData()"
							>{{ $t('update') }}</el-button
						>
					</div>
				</div>
			</el-card>
		</el-col>
		<el-col :span="16">
			<el-card shadow="hover">
				<template #header>
					<div class="card-header">
						<h4>{{ $t('schoolInfo') }}</h4>
						<el-button
							class="button"
							type="primary"
							@click="updateSchoolData()"
							>{{ $t('update') }}</el-button
						>
					</div>
				</template>
				<div class="text-center">
					<el-form
						label-position="left"
						label-width="150px"
						ref="schoolDataForm"
						:model="schoolData"
						:rules="rules">
						<el-form-item prop="name" :label="$t('schoolName')">
							<el-input
								type="text"
								:placeholder="$t('schoolName')"
								v-model="schoolData.name" />
						</el-form-item>

						<el-form-item prop="description" :label="$t('description')">
							<el-input
								type="textarea"
								:placeholder="$t('description')"
								v-model="schoolData.description" />
						</el-form-item>

						<el-form-item prop="address" :label="$t('address')">
							<el-input
								type="textarea"
								:placeholder="$t('address')"
								v-model="schoolData.address" />
						</el-form-item>

						<el-form-item prop="phone" :label="$t('phone')">
							<el-input
								type="phone"
								:placeholder="$t('phone')"
								v-model="schoolData.phone">
								<template #append>
									<el-button icon="phone"></el-button>
								</template>
							</el-input>
						</el-form-item>

						<el-form-item prop="email" :label="$t('emailAddress')">
							<el-input
								type="email"
								:placeholder="$t('emailAddress')"
								v-model="schoolData.email">
								<template #append>
									<el-button icon="message"></el-button>
								</template>
							</el-input>
						</el-form-item>

						<el-form-item prop="postalBank" :label="$t('postalBank')">
							<el-input
								type="text"
								:placeholder="$t('postalBank')"
								v-model="schoolData.postalBank" />
						</el-form-item>

						<el-form-item prop="website" :label="$t('website')">
							<el-input
								type="text"
								:placeholder="$t('website')"
								v-model="schoolData.website" />
						</el-form-item>
					</el-form>
				</div>
			</el-card>
		</el-col>
	</el-row>

	<el-card shadow="hover" class="mb-10">
		<el-row align="middle" class="mb-10">
			<el-col :span="12">
				<div class="text-left">
					<h4>{{ $t('schoolyearChangeMode') }}</h4>
				</div>
			</el-col>
			<el-col :span="12">
				<div class="text-right">
					<el-switch
						:width="50"
						style="display: block"
						active-color="#c3a171"
						inactive-color="#9e7d33"
						:inactive-text="$t('manual')"
						:active-text="$t('automatic')"
						v-model="schoolyearChangeMode"
						@change="updateSchoolyearChangeMode">
					</el-switch>
				</div>
			</el-col>
		</el-row>
		<el-row align="middle">
			<el-col :span="12">
				<div class="text-left">
					<h4>{{ $t('currentSchoolyear') }}</h4>
				</div>
			</el-col>
			<el-col :span="12">
				<div class="text-right">
					<el-select
						filterable
						v-model="currentSchoolyearValue"
						:placeholder="$t('select')"
						:no-data-text="$t('noData')"
						:no-match-text="$t('noMatchingData')"
						:loading-text="$t('loading...')"
						:disabled="schoolyearChangeMode"
						:loading="loadingSettings"
						@change="updateCurrentSchoolyear">
						<el-option
							v-for="item in schoolyears"
							:label="item.code"
							:value="item.id"
							:key="item.id">
						</el-option>
					</el-select>
				</div>
			</el-col>
		</el-row>
	</el-card>

	<el-card shadow="hover" class="mb-10">
		<el-row align="middle">
			<el-col :span="12">
				<div class="text-left">
					<h4>{{ $t('adminSpaceSchoolyear') }}</h4>
				</div>
			</el-col>
			<el-col :span="12">
				<div class="text-right">
					<el-select
						filterable
						v-model="adminSchoolyearValue"
						:placeholder="$t('select')"
						:no-data-text="$t('noData')"
						:no-match-text="$t('noMatchingData')"
						:loading-text="$t('loading...')"
						:loading="loadingSettings"
						@change="updateAdminSchoolyear">
						<el-option
							v-for="item in schoolyears"
							:label="item.code"
							:value="item.id"
							:key="item.id">
						</el-option>
					</el-select>
				</div>
			</el-col>
		</el-row>
	</el-card>

	<el-row align="middle" :gutter="10" class="mb-10">
		<el-col :span="12">
			<el-card shadow="hover">
				<el-row align="middle">
					<el-col :span="12">
						<div class="text-left">
							<h4>{{ $t('lockData') }}</h4>
						</div>
					</el-col>
					<el-col :span="12">
						<div class="text-right">
							<el-switch
								:loading="loadingSettings"
								@change="updateLockedData"
								v-model="lockedDataValue"
								:width="50" />
						</div>
					</el-col>
				</el-row>
			</el-card>
		</el-col>
		<el-col :span="12">
			<el-card shadow="hover">
				<el-row align="middle">
					<el-col :span="12">
						<div class="text-left">
							<h4>{{ $t('yearCutting') }}</h4>
						</div>
					</el-col>
					<el-col :span="12">
						<div class="text-right">
							<el-select
								v-model="schoolMoreData.yearCuttingType"
								:placeholder="$t('select')"
								:no-data-text="$t('noData')"
								:no-match-text="$t('noMatchingData')"
								@change="updateSchoolMoreData">
								<el-option
									v-for="item in yearCuttingTypes"
									:label="item.label"
									:value="item.id"
									:key="item.id">
								</el-option>
							</el-select>
						</div>
					</el-col>
				</el-row>
			</el-card>
		</el-col>
	</el-row>
</template>

<script>
	import { mapState, mapGetters, mapActions } from 'vuex'

	import { APP_BASE_URL, STORAGE_API_BASE_URL } from '@/utils/constants'

	import {
		Settings,
		SchoolData,
		VarTypes,
		YearCuttingTypes,
	} from '@/utils/enums'

	import accessManager from '@/mixins/access-manager'

	export default {
		name: 'AppSettings',
		mixins: [accessManager],
		data() {
			return {
				yearCuttingTypes: [],
				lockedDataValue: false,
				adminSchoolyearValue: '',
				currentSchoolyearValue: '',
				schoolyearChangeMode: '',
				loadingSettings: true,
				logo: null,
				logoData: {
					folder: 'logos',
				},
				schoolData: {
					name: '',
					description: '',
					address: '',
					phone: '',
					email: '',
					postalBank: '',
					website: '',
				},
				schoolMoreData: {
					yearCuttingType: '',
				},
				rules: {
					name: [
						{
							required: true,
							message: this.$t('fieldRequired'),
							trigger: 'blur',
						},
					],
					address: [
						{
							required: true,
							message: this.$t('fieldRequired'),
							trigger: 'blur',
						},
					],
					phone: [
						{
							required: true,
							message: this.$t('fieldRequired'),
							trigger: 'blur',
						},
					],
				},
				STORAGE_API_BASE_URL,
			}
		},
		created() {
			this.init()

			var self = this

			this.$_.forIn(YearCuttingTypes, function (value, key) {
				self.yearCuttingTypes.push(value)
			})
		},
		computed: {
			...mapState({
				userData: (state) => state.session.userData,
				schoolyears: (state) => state.schoolyears.schoolyears,
			}),
			...mapGetters({
				getSettingValue: 'settings/getSettingValue',
				getSchoolData: 'schooldata/getSchoolDataValue',
			}),
		},
		methods: {
			...mapActions({
				fetchSettingsDB: 'settings/fetchSettingsDB',
				fetchSchoolDataDB: 'schooldata/fetchSchoolDataDB',
				getUserByCredentials: 'authentication/getUserByCredentials',
			}),
			async init() {
				this.adminSchoolyearValue = this.getSettingValue(
					Settings.ADMIN_SCHOOLYEAR.NAME
				)
				this.currentSchoolyearValue = this.getSettingValue(
					Settings.CURRENT_SCHOOLYEAR.NAME
				)
				this.lockedDataValue =
					this.getSettingValue(Settings.LOCKED_DATA.NAME, VarTypes.NUMBER) !== 0
				this.schoolyearChangeMode =
					this.getSettingValue(Settings.SCHOOLYEAR_CHANGE_MODE.NAME) === 'auto'

				this.loadingSettings = false

				this.logo = this.getSchoolData(SchoolData.LOGO.NAME).replace(
					'http://localhost',
					APP_BASE_URL
				)

				this.schoolData.name = this.getSchoolData(SchoolData.NAME.NAME)
				this.schoolData.description = this.getSchoolData(
					SchoolData.DESCRIPTION.NAME
				)
				this.schoolData.address = this.getSchoolData(SchoolData.ADDRESS.NAME)
				this.schoolData.phone = this.getSchoolData(SchoolData.PHONE.NAME)
				this.schoolData.email = this.getSchoolData(SchoolData.EMAIL.NAME)
				this.schoolData.postalBank = this.getSchoolData(
					SchoolData.POSTAL_BANK.NAME
				)
				this.schoolData.website = this.getSchoolData(SchoolData.WEBSITE.NAME)

				this.schoolMoreData.yearCuttingType = parseInt(
					this.getSchoolData(SchoolData.YEAR_CUTTING_TYPE.NAME)
				)
			},
			async updateSchoolyearChangeMode(changeMode) {
				this.$prompt('', this.$t('confirmation'), {
					inputPlaceholder: this.$t('confirmWithPassword'),
					confirmButtonText: this.$t('confirm'),
					cancelButtonText: this.$t('cancel'),
					inputType: 'password',
					callback: async (cb) => {
						if (cb.action !== 'cancel' && cb.action !== 'close' && cb.value) {
							let resp = await this.getUserByCredentials({
								username: this.userData.uid,
								password: cb.value,
							})

							if (resp.success) {
								if (
									resp.data.username &&
									resp.data.password &&
									resp.data.user
								) {
									await this.fetchSettingsDB({
										action: 'updateSetting',
										params: {
											name: Settings.SCHOOLYEAR_CHANGE_MODE.NAME,
											value: changeMode ? 'auto' : 'manual',
										},
									})

									await this.fetchSettingsDB({ action: 'getSettings' })

									this.$message.success(this.$t('success'))
								} else {
									this.$message.error(this.$t('wrongPassword'))
								}
							} else {
								this.$message.error(this.$t('anErrorOccured'))
							}
						}

						this.init()
					},
				})
			},
			async updateAdminSchoolyear(value) {
				await this.fetchSettingsDB({
					action: 'updateSetting',
					params: {
						name: Settings.ADMIN_SCHOOLYEAR.NAME,
						value: value,
					},
				})

				await this.fetchSettingsDB({ action: 'getSettings' })

				this.$message.success(this.$t('success'))
			},
			async updateCurrentSchoolyear(value) {
				await this.fetchSettingsDB({
					action: 'updateSetting',
					params: {
						name: Settings.CURRENT_SCHOOLYEAR.NAME,
						value: value,
					},
				})

				await this.fetchSettingsDB({ action: 'getSettings' })

				this.$message.success(this.$t('success'))
			},
			async updateLockedData(value) {
				await this.fetchSettingsDB({
					action: 'updateSetting',
					params: {
						name: Settings.LOCKED_DATA.NAME,
						value: value ? 1 : 0,
					},
				})

				await this.fetchSettingsDB({ action: 'getSettings' })

				this.$message.success(this.$t('success'))
			},
			async updateSchoolData() {
				this.$refs['schoolDataForm'].validate(async (valid) => {
					if (valid) {
						await this.fetchSchoolDataDB({
							action: 'updateSchoolData',
							params: [
								{
									name: SchoolData.NAME.NAME,
									value: this.schoolData.name,
								},
								{
									name: SchoolData.DESCRIPTION.NAME,
									value: this.schoolData.description,
								},
								{
									name: SchoolData.ADDRESS.NAME,
									value: this.schoolData.address,
								},
								{
									name: SchoolData.PHONE.NAME,
									value: this.schoolData.phone,
								},
								{
									name: SchoolData.EMAIL.NAME,
									value: this.schoolData.email,
								},
								{
									name: SchoolData.POSTAL_BANK.NAME,
									value: this.schoolData.postalBank,
								},
								{
									name: SchoolData.WEBSITE.NAME,
									value: this.schoolData.website,
								},
							],
						})

						await this.fetchSchoolDataDB({ action: 'getSchoolData' })

						this.$message.success(this.$t('success'))
					} else {
						return false
					}
				})
			},
			async updateSchoolMoreData() {
				await this.fetchSchoolDataDB({
					action: 'updateSchoolData',
					params: [
						{
							name: SchoolData.YEAR_CUTTING_TYPE.NAME,
							value: this.schoolMoreData.yearCuttingType,
						},
					],
				})

				await this.fetchSchoolDataDB({ action: 'getSchoolData' })

				this.$message.success(this.$t('success'))
			},
			async updateLogo() {
				await this.fetchSchoolDataDB({
					action: 'updateSchoolData',
					params: [
						{
							name: SchoolData.LOGO.NAME,
							value: this.logo,
						},
					],
				})

				await this.fetchSchoolDataDB({ action: 'getSchoolData' })

				this.$message.success(this.$t('success'))
			},
			handleLogoError(err, file) {
				this.$message.error(this.$t('anErrorOccured'))
			},
			handleLogoSuccess(res, file) {
				this.logo =
					STORAGE_API_BASE_URL +
					'root/' +
					this.logoData.folder +
					'/' +
					file.name

				this.updateLogo()
			},
			beforeLogoUpload(file) {
				const isImg =
					file.type === 'image/jpeg' ||
					'image/jpg' ||
					'image/gif' ||
					'image/png'
				const sizeLessThan3M = file.size / 1024 / 1024 < 3

				if (!isImg) this.$message.error(this.$t('uploadImageFormatWarning'))

				if (!sizeLessThan3M)
					this.$message.error(this.$t('uploadFileSizeWarning', [3]))

				return isImg && sizeLessThan3M
			},
			clearLogo() {
				this.logo = null

				this.updateLogo()
			},
		},
	}
</script>

<style scoped>
	::v-deep(.el-upload-dragger) {
		width: unset;
		height: unset;
		justify-content: center;
		align-items: center;
		display: flex;
		padding: 5px;
	}

	.card-header,
	.card-footer {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
</style>
